import dayjs from 'dayjs'
import solarlunar from 'solarlunar'

export default {
  methods: {
    hasError (model, handle) {
      return model.errors && model.errors[handle]
    },
    commaFormat (value) {
      return Math.floor(value)
        .toString()
        .replace(/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/, (all, pre, groupOf3Digital) => {
          return pre + groupOf3Digital.replace(/\d{3}/g, ',$&')
        }).replace('.00', '')
    },
    /**
     * @param {Number} t 要換算生辰的時間
     */
    covChinaTime (t) {
      t = parseInt(t)
      let selectTime = 0
      if (t === 23) {
        selectTime = (t / 2)
      } else {
        selectTime = (Math.round(t / 2))
      }

      selectTime = selectTime.toString()
      switch (selectTime) {
        case '0':
          return '早子'
        /* eslint-disable */
        case '1':
          return '丑'
        case '2':
          return '寅'
        case '3':
          return '卯'
          break
        case '4':
          return '辰'
          break
        case '5':
          return '巳'
          break
        case '6':
          return '午'
          break
        case '7':
          return '未'
          break
        case '8':
          return '申'
          break
        case '9':
          return'酉'
          break
        case '10':
          return'戌'
          break
        case '11':
          return'亥'
          break
        case '11.5':
          return'晚子'
          break
        default:
          return '吉時'
      }

      // solarlunar.zhi = ['子', '丑', '寅', '卯', '辰', '巳', '午', '未', '申', '酉', '戌', '亥']
      // const chinaTimes = solarlunar.zhi
      // console.log('covChinaTime', t)
      // // console.log('chinaTimes', chinaTimes)
      // const time = Number(t)
      // if (!isNaN(time)) { // 3 => 1.00001
      //   const chinaTime = chinaTimes[Math.ceil((time - 0.9999) / 2)]
      //   if (time <= 1) {
      //     return '早子'
      //   } else if (time >= 23) {
      //     return '晚子'
      //   }
      //   return chinaTime + '時'
      // } else if (t === '吉') {
      //   return '吉時'
      // }
      // return ''
    },
    dayFormat (time, format) {
      if (time) {
        if (format === '國曆') {
          return dayjs(time).format(`民國${dayjs(time).format('YYYY') - 1911}年MM月DD日`)
        } else if (format === '農曆') {
          const lunar = solarlunar.solar2lunar(
            dayjs(time).format('YYYY'),
            dayjs(time).format('MM'),
            dayjs(time).format('DD')
          )
          return `民國${lunar.lYear - 1911}年${lunar.lMonth}月${lunar.lDay}日`
        } else if (format === '西元') {
          return dayjs(time).format('YYYY年MM月DD日')
        } else if (format === 'CC') {
          const date = time.split('/')
          return `民國${date[0]}年${date[1]}月${date[2]}日`
        } else if (format === 'LC') {
          const date = time.split('/')
          return `民國${date[0]}年${date[1]}月${date[2]}日`
        } else if (format === 'AD') {
          const date = time.split('/')
          return `西元${date[0]}年${date[1]}月${date[2]}日`
        }
        return dayjs(time).format(format)
      }
      return ''
    },
    //農暦:93年閨2月5日14時30分
    //國暦:93年3月25日14時30分
    dayFormatLuna (time, format, modal) {
      let leap = ''
      if(modal.fortune_is_leap === 1||modal.fortune_is_leap === '1') {
        leap = '閏'
      }
      let fortune_hour=0;
      let fortune_minute=0;
      if(modal.fortune_hour) {
        fortune_hour = modal.fortune_hour.toString().padStart(2, '0')
      }
      if(modal.fortune_minute) {
        fortune_minute = modal.fortune_minute.toString().padStart(2, '0')
      }

      if (time) {
        if (format === '國曆') {
          return dayjs(time).format(`民國${dayjs(time).format('YYYY') - 1911}年MM月DD日`)
        } else if (format === '農曆') {
          const lunar = solarlunar.solar2lunar(
            dayjs(time).format('YYYY'),
            dayjs(time).format('MM'),
            dayjs(time).format('DD')
          )
          return `民國${lunar.lYear - 1911}年${lunar.lMonth}月${lunar.lDay}日${fortune_hour === '0吉' ? `吉時` : `${fortune_hour}時${fortune_minute}分`}`
        } else if (format === '西元') {
          return dayjs(time).format('YYYY年MM月DD日')
        } else if (format === 'CC') {
          const date = modal.result.CC.split('/')
          return `民國${date[0]}年${date[1]}月${date[2]}日${fortune_hour === '0吉' ? `吉時` : `${fortune_hour}時${fortune_minute}分`}`
        } else if (format === 'LC') {
          const date = modal.result.LC.split('/')
          return `民國${date[0]}年${modal.result.leap=='1'?'閏':''}${date[1]}月${date[2]}日${fortune_hour === '0吉' ? `吉時` : `${fortune_hour}時${fortune_minute}分`}`
        } else if (format === 'AD') {
          const date = modal.result.AD.split('/')
          return `西元${date[0]}年${date[1]}月${date[2]}日${fortune_hour === '0吉' ? `吉時` : `${fortune_hour}時${fortune_minute}分`}`
        }
        return dayjs(time).format(format)
      }
      return ''
    },
    timeFormat (time, format) {
      if (time) {
        return dayjs('0000/00/00 ' + time).format(format)
      }
      return ''
    },
    isLuckyProduct (number) { // 判斷是否為開運商品
      switch (String(number)) {
        case '01001': // 開運
        case '01002': // 開運
        case '01003': // 開運
        case '01004': // 開運
        case '01005': // 開運
        case '01006': // 開運
        case '11006': // 開運
        case '01205': // 開運戒指
        case '01206': // 開運墜子
        case '01207': // 開運領帶夾
        case '01208': // 開運胸針
        case '01209': // 開運腕飾
          return true
        default:
          return false
      }
    },
    maxLimitedBuy (product) { // 最大可購買數量
      // products.quantity 可能會有負值出現
      if (this.isLuckyProduct(product.product_number)) {
        return 1
      }
      if (product && (product.quantity > 0)) {
        if (product.max_limited_buy) {
          return Math.min(product.max_limited_buy, product.quantity)
        }
        return Math.min(50, product.quantity)
      }
      return 0
    },
    isBusiness (user) {
      if (/^[A-Z]/.test(user.identity_card)) {
        return false
      }
      return true
    }
  }
}
